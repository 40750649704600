<template>
  <div class="About">
    <!-- 3-1 英雄総選挙とは -->
    <section class="About__Introduce Introduce">
      <h2 class="Introduce__Title" v-html="$t('about.001')" />
      <p class="Introduce__Text" v-html="$t('about.002')" />
      <img class="Introduce__Charactors" :src="require('@images/about/heroes/charactors.png')" alt="">
      <vote-notice class="Introduce__Notice"/>
    </section>
    <section class="About__Gift Gift">
      <p class="Gift__Em" v-html="$t('about.003')" />
      <div class="Gift__wallpaper">
        <img src="@images/about/wallpaper.png" alt="">
      </div>
      <p class="Gift__Em" v-html="$t('about.004')" />
      <p class="Gift__Text">
        {{ $t('about.005') }}<br>
        {{ $t('about.006') }}
      </p>
      <div class="Gift__Coin">
        <img src="@images/about/coin.png" alt="">
        <p>
          <span v-html="$t('about.007')" /><br>
          <span class="Gift__CoinValue" v-html="$t('about.008')" />
        </p>
      </div>
    </section>
    <section class="About__History">
      <history-item
        v-for="(item, index) in historyItems"
        :key="index"
        class="About__HistoryItem"
        :count="index + 1"
        :title="item.title"
        :url="item.url"
      />
    </section>
  </div>
</template>

<script>
import LocaleMixin from '@/mixins/locale'
import LoaderMixin from '@/mixins/loader'
import VoteNotice from '@/components/global/vote-notice.vue'
import HistoryItem from '@/components/about/history-item.vue'

export default {
  name: 'About',
  mixins: [LocaleMixin, LoaderMixin],
  components: {
    VoteNotice,
    HistoryItem
  },
  computed: {
    historyItems () {
      // TODO: URL差し替え
      return [
        {
          title: this.$t('about.009'),
          url: 'https://events.fire-emblem-heroes.com/vote/result'
        },
        {
          title: this.$t('about.010'),
          url: 'https://events.fire-emblem-heroes.com/vote2/result'
        },
        {
          title: this.$t('about.011'),
          url: 'https://vote3.campaigns.fire-emblem-heroes.com/results'
        },
        {
          title: this.$t('about.012'),
          url: `https://vote4.campaigns.fire-emblem-heroes.com/${this.locale.slice(0, 3)}${this.locale.substr(-2).toUpperCase()}/results`
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.About {
  @at-root {
    & {
    }
  }
  .About__History {
    margin-top: 60px;
  }
  .About__HistoryItem {
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.Introduce{
  @at-root {
    & {
      padding-top: 25px;
    }
    .Introduce__Title {
      font-size: $fs-3xlarge;
      text-align: center;
      text-shadow: $ts-base;
      padding: 0 20px;
    }
    .Introduce__Text {
      text-align: center;
      text-shadow: $ts-base;
      margin-top: 20px;
      padding: 0 20px;
    }
    .Introduce__Charactors {
      margin-top: 20px;
    }
    .Introduce__Notice {
      margin-top: 20px;
    }
  }
}

.Gift {
  @at-root {
    & {
      margin-top: 60px;
      text-align: center;
      text-shadow: $ts-base;
    }
    .Gift__Em {
      font-size: $fs-3xlarge;
      font-weight: $bold;
      padding: 0 20px;
    }
    .Gift__wallpaper {
      margin: 80px 0;
      position: relative;
      > img {
        position: relative;
        z-index: 1;
      }
      &::before {
        content: '';
        display: block;
        background: url(#{$img-path}about/bg_spacial.png) no-repeat center center;
        width: 611px;
        height: 613px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .Gift__Text {
      margin: 30px 0;
      padding: 0 20px;
      font-weight: $bold;
    }
    .Gift__Coin {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 450px;
      margin: 0 auto;
      > *:last-child {
        flex-grow: 1;
        text-align: left;
        margin-left: 40px;
      }
    }
    .Gift__CoinValue {
      font-size: $fs-4xlarge;
    }
  }
}
</style>
