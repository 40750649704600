<template>
  <div class="HistoryItem">
    <h3 class="HistoryItem__Title" v-html="title" />
    <img class="HistoryItem__Charactors" :src="charactors" alt="">
    <button-a class="HistoryItem__Button" @click="goToPage">
      <img :src="textGoResult" :alt="$t('about.013')">
    </button-a>
  </div>
</template>
<script>
import LocaleMixin from '@/mixins/locale'
import ButtonA from '@/components/global/button-a.vue'
export default {
  name: 'HistoryItem',
  mixins: [LocaleMixin],
  components: {
    ButtonA
  },
  props: {
    count: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    charactors () {
      return require(`@images/about/heroes/charactors${this.count}.png`)
    },
    textGoResult () {
      return require(`@images/about/${this.locale}/go-result.png`)
    }
  },
  methods: {
    goToPage () {
      window.open(this.url, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.HistoryItem {
  @at-root {
    & {
      padding: 20px 0 40px 0;
      background: url(#{$img-path}about/bg_history-item.png) no-repeat center top;
      background-size: 100% 100%;
    }
    .HistoryItem__Title {
      color: $color-black;
      font-size: $fs-3large;
      font-weight: $bold;
      text-align: center;
      line-height: 56px;
      background: url(#{$img-path}about/bg_history-item-title.png) no-repeat center top;
      background-size: 100% auto;
    }
    .HistoryItem__Charactors {
      margin: 20px 0 40px 0;
    }
    .HistoryItem__Button {
      @include tap-event();
      margin: 0 auto;
      width: 560px;
    }
  }
}
</style>
